import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Wave from '../components/wave';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Contact Me`}</h1>
    <p>{`You can get in touch with me by opening the nearest window and yelling real loud.`}</p>
    <h2>{`Or You Can Wave To Me`}</h2>
    <p>{`Just click this button.`}</p>
    <Wave mdxType="Wave" />
    <p>{`Pleas note that i may not wave back.`}</p>
    <div style={{
      background: 'red'
    }}>
      <h2>{`this is importat`}</h2>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      